import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateUtilsService {

    constructor() { }

    public now() {
        return moment().toDate();
    }

    public setDefaultTimezon(tz: string) {
        moment.tz.setDefault(tz);
    }

    public formatMinutes(minutes: number, format = 'HH:mm') {
        return moment.utc().startOf('day').add(minutes, 'minutes').format(format);
    }

    public minutesToDate = (minutes: number) => {
        return moment().startOf('day').add(minutes, 'minutes').toDate();
    }

    public hoursToDate = (hours: number) => {
        return moment().startOf('day').add(hours, 'hours').toDate();
    }

    public timeToDate(time: string) {
        return moment(time, 'HH:mm').toDate();
    }

    public setTimeToDate(time: string, date: Date) {
        const newDate = moment(date);
        const dateWithTime = moment(time, 'HH:mm');
        newDate.hours(dateWithTime.hours());
        newDate.minutes(dateWithTime.minutes());
        newDate.seconds(dateWithTime.seconds());
        return newDate.toDate();
    }

    public formatTime(time: string, format = 'hh:mm A') {
        return moment(time, 'H:mm').format(format);
    }

    public formatDate(date: Date, format = 'MMM DD, YYYY') {
        return moment(date).format(format);
    }

    public toNow(date: Date) {
        return moment(date).toNow();
    }

    public fromNow(date: Date) {
        return moment(date).fromNow();
    }

    public addMinutesToDate(date: any, minutes: number = 25) {
        return moment(date).add(minutes, 'minutes').toDate();
    }

    public addMinutesToTime(time: string, minutes: number) {
        return moment(time, 'H:mm').add(minutes, 'minutes').toDate();
    }

    public getTotalClockMinutes(date: Date) {
        const m = moment(date);
        let hours = m.hours();
        const minutes = m.minutes();
        if (hours > 12) {
            hours -= 12;
        }
        return (hours * 60) + minutes;
    }

    public getTotalDayMinutes(date: Date) {
        const m = moment(date);
        const hours = m.hours();
        const minutes = m.minutes();
        return (hours * 60) + minutes;
    }

    public dateToISO(date: Date) {
        const IsoFormat = 'YYYY-MM-DD';
        return moment(date).format(IsoFormat);
    }

    public momentToISO(moment: any) {
        const IsoFormat = 'YYYY-MM-DD';
        return moment.format(IsoFormat);
    }

    public dateFromISO(iso: string) {
        return moment(iso).toDate();
    }

    public dateFormattedWithName = (date: any, format = 'MMM D YYYY') => {
        const m = moment(date);
        return {
            formated: m.format(format),
            name: m.calendar().split(' ')[0]
        };
    }

    public isSameDay(first: Date, second: Date) {
        return moment(first).isSame(second, 'day');
    }

    public isBefore(first: Date, second: Date) {
        return moment(first).isBefore(second);
    }

    public isAfter(first: Date, second: Date) {
        return moment(first).isAfter(second);
    }

    public isWithinMinute(first: Date, second: Date) {
        return (moment(second).valueOf() - moment(first).valueOf()) / 1000 < 60;
    }

    public minutesBetween(date1: Date, date2: Date) {
        return (date2.getTime() - date1.getTime()) / 1000 / 60;
    }

    public tomorrow() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow;
    }

    public roundEstimatedTimeWindowStart(date: Date, startBefore?: string) {
        var roundedDate = this.roundToHalfHour(date);
        const isBefore8Job = (startBefore || '23:59') < '08:01';
        //Jobs before 8am time window must be 6 to 8
        if(isBefore8Job && (date.getHours()<8 || (date.getHours()===8 && date.getMinutes()===0))){
            roundedDate.setHours(6);
            roundedDate.setMinutes(0);
            return roundedDate;
        }
        if(date.getHours()<10){
            roundedDate.setHours(8);
            roundedDate.setMinutes(0);
            return roundedDate;
        }
        if (roundedDate.getHours() > 16 || (roundedDate.getHours() === 16 && date.getMinutes()>45)) {
            roundedDate.setHours(15);
            roundedDate.setMinutes(0);
            return roundedDate;
        }
        roundedDate.setHours(roundedDate.getHours() - 2);
        return roundedDate;
    }

    public roundEstimatedTimeWindowEnd(date: Date, startBefore?: string) {
        var roundedDate = this.roundToHalfHour(date);
        const isBefore8Job = (startBefore || '23:59') < '08:01';
        //Jobs before 8am time window must be 6 to 8
        if(isBefore8Job && (date.getHours()<8 || (date.getHours()===8 && date.getMinutes()===0))){
            roundedDate.setHours(8);
            roundedDate.setMinutes(0);
            return roundedDate;
        }
        if(date.getHours()<10){
            roundedDate.setHours(12);
            roundedDate.setMinutes(0);
            return roundedDate;
        }
        //Santity check, to avoid setting the next day
        if (roundedDate.getHours() > 16 || (roundedDate.getHours() === 16 && date.getMinutes()>45)) {
            roundedDate.setHours(19);
            roundedDate.setMinutes(0);
        }
        else {
            roundedDate.setHours(roundedDate.getHours() + 2);
        }
        return roundedDate;
    }

    public roundToHalfHour(date: Date) {
        var date1 = new Date(date.getTime());
        if (date1.getMinutes() <= 15)
            date1.setMinutes(0);
        if (date1.getMinutes() >= 15 && date1.getMinutes() <= 45)
            date1.setMinutes(30);
        if (date1.getMinutes() > 45) {
            if (date1.getHours() != 23) {
                date1.setMinutes(0);
                date1.setHours(date1.getHours() + 1);
            }
        }

        return date1;
    }
}
