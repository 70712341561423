import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PlanningState } from 'src/app/planning/state';

export interface UserPreferences {
    mapStraightLines: boolean;
    mapType: 'street' | 'satellite' | 'dark';
}

@Component({
    selector: 'app-user-preferences',
    templateUrl: './userPreferences.component.html',
    styleUrls: ['./userPreferences.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPreferencesComponent implements OnInit {
    @Input() dismissFn: () => void;
    @Input() preferences: UserPreferences;
    
    public preferencesForm: FormGroup;
    public saving$ = new BehaviorSubject<boolean>(false);

    constructor(
        private fb: FormBuilder,
        private utilities: UtilitiesService,
        private fns: AngularFireFunctions,
        private state: PlanningState
    ) {}

    ngOnInit() {
        this.preferencesForm = this.fb.group({
            mapStraightLines: [this.preferences?.mapStraightLines ?? false],
            mapType: [this.preferences?.mapType ?? 'street']
        });
    }

    async savePreferences() {
        try {
            this.saving$.next(true);
            const preferences = this.preferencesForm.value;
            const callable = this.fns.httpsCallable('savePreferences');
            await callable(preferences).toPromise();
            this.state.userPreferences$.next(preferences);
            this.utilities.notifySuccess('Preferences saved successfully');
            this.dismissFn();
        } catch (error) {
            console.error('Error saving preferences:', error);
            this.utilities.notifyError('Error saving preferences');
        } finally {
            this.saving$.next(false);
        }
    }
} 