<div class="header">
    User Preferences
</div>
<div class="body">
    <form [formGroup]="preferencesForm" (ngSubmit)="savePreferences()" class="form">
        <div class="form-content">
            <div class="form-row">
                <mat-checkbox formControlName="mapStraightLines">
                    Use straight lines on map
                </mat-checkbox>
            </div>
            <div class="form-row">
                <mat-form-field>
                    <mat-label>Map Type</mat-label>
                    <mat-select formControlName="mapType">
                        <mat-option value="street">Street</mat-option>
                        <mat-option value="satellite">Satellite</mat-option>
                        <mat-option value="dark">Dark</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="footer">
            <button mat-button type="button" (click)="dismissFn()">Cancel</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </form>
</div> 