import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DataEnvService } from '../shared/sl-data/data-env.service';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, Observable, map, switchMap, combineLatest, shareReplay } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { MainNavStateService } from '../components/main-nav/state.service';
import { UtilitiesService } from '../services/utilities.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { ActivatedRoute } from '@angular/router';

interface StockSummary {
  clientName: string;
  totalRequested: number;
  totalMissing: number;
}

interface StockDetail {
  clientName: string;
  sku: string;
  description: string;
  requestedAmount: number;
  availableAmount: number;
  missing: number;
  orders: Array<{ orderId: number; orderNumber: string; amount: number; }>;
  itemsInStock: Array<{ itemId: string; serialNumer: string; statusId: number; }>;
}

interface StockReport {
  stock: StockDetail[];
  generatedAt: Timestamp;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportComponent implements OnInit {

  private getNextWorkingDate = () => {
    const today = new Date();
    const nextDay = new Date();
    nextDay.setDate(today.getDate() + 1);
    while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay.toISOString().split('T')[0];
  }

  private selectedStartDate$ = new BehaviorSubject<string>(this.getNextWorkingDate());
  public selectedStartDate = new Date(this.getNextWorkingDate() + 'T00:00:00');
  
  dataIsChanging: boolean = false;
  public featureFlags$ = this.dataEnv.featureFlags$;

  // Reactive currentReport$ stream
  public currentReport$ = combineLatest([this.selectedStartDate$, this.dataEnv.selectedHub$])
    .pipe(
      switchMap(([selectedStartDate, selectedHub]) => {
        return this.firestore
          .doc<StockReport>(`com/stella/stockReports/${selectedStartDate}`)
          .valueChanges()
          .pipe(
            map(data => data as StockReport | null)
          );
      }),
      shareReplay(1)
    );

  public generatedAt$ = this.currentReport$.pipe(
    map(report => report?.generatedAt?.toDate())
  );

  public stockData$ = this.currentReport$.pipe(
    map(report => report?.stock || [])
  );

  // Data sources for the grids
  summaryDataSource: DataSource;
  detailDataSource: DataSource;
  
  // Selected client for filtering
  selectedClient$ = new BehaviorSubject<string>(null);
  //reportGeneratedAt$ = new BehaviorSubject<string>(null);
  
  // Selected detail row for showing orders and stock
  selectedDetailRow: StockDetail | null = null;

  public generating$ = new BehaviorSubject<boolean>(false);

  constructor(
    private fns: AngularFireFunctions,
    private dataEnv: DataEnvService,
    private utilities: UtilitiesService,
    public mainNav: MainNavStateService,
    private firestore: AngularFirestore,
    private route: ActivatedRoute
  ) {
    this.initializeSummaryDataSource();
    this.initializeDetailDataSource();
    this.getStatusText = this.getStatusText.bind(this);

    // Subscribe to stockData$ to update the local stockData array
    this.stockData$.subscribe(stock => {
      this.stockData = stock;
      this.reloadData();
    });
  }
  
  private stockData : StockDetail[] = [];

  private initializeSummaryDataSource() {
    const store = new CustomStore({
      key: "clientName",
      load: async (loadOptions: any) => {
        
        // Group by client and calculate totals
        const summaryData = Object.values(this.stockData.reduce((acc, curr) => {
          if (!acc[curr.clientName]) {
            acc[curr.clientName] = {
              clientName: curr.clientName,
              totalRequested: 0,
              totalMissing: 0
            };
          }
          acc[curr.clientName].totalRequested += curr.requestedAmount;
          acc[curr.clientName].totalMissing += curr.missing;
          return acc;
        }, {} as { [key: string]: StockSummary }));

        return {
          data: summaryData
        };
      }
    });

    this.summaryDataSource = new DataSource({
      store
    });
  }

  private initializeDetailDataSource() {
    const store = new CustomStore({
      key: "sku",
      load: async (loadOptions: any) => {
        
        const selectedClient = this.selectedClient$.value;

        // Filter by selected client if any
        const filteredData = selectedClient 
          ? this.stockData.filter(item => item.clientName === selectedClient)
          : this.stockData;

        return {
          data: filteredData
        };
      }
    });

    this.detailDataSource = new DataSource({
      store
    });
  }

  public reportTotals$ = this.currentReport$.pipe(
    map(rep=>{
      const clientsMissingStock = rep.stock.filter(clientStock=>clientStock.missing>0).length;
      const totalStockMissing = rep.stock.reduce((acc, clientStock)=>acc + clientStock.missing, 0);
      console.log(totalStockMissing);
      return {clientsMissingStock, totalStockMissing}
    })
  );

  private async loadStockData() {
    return this.stockData;
  }

  public onSummaryRowClick(e: any) {
    const clientName = e.data.clientName;
    this.selectedClient$.next(clientName);
    this.detailDataSource.reload();
  }

  public onDetailRowClick(e: any) {
    this.selectedDetailRow = e.data;
  }

  public yesterdayClickHandler() {
    const newDate = moment().add(-1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedStartDate$.next(newDate.toISOString().split('T')[0]);
  }

  public todayClickHandler() {
    const newDate = moment().toDate();
    this.selectedStartDate = newDate;
    this.selectedStartDate$.next(newDate.toISOString().split('T')[0]);
  }

  public tomorrowClickHandler() {
    const newDate = moment().add(1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedStartDate$.next(newDate.toISOString().split('T')[0]);
  }

  public dateChangedStart(e) {
    if (e.value) {
      const dateStr = e.value.toISOString().split('T')[0];
      this.selectedStartDate = e.value;
      this.selectedStartDate$.next(dateStr);
    }
  }

  private reloadData() {
    this.selectedClient$.next(null);
    this.selectedDetailRow = null;
    this.summaryDataSource.reload();
    this.detailDataSource.reload();
  }

  async ngOnInit() {
    if(this.route.snapshot.queryParams['date']){
      this.selectedStartDate = new Date(this.route.snapshot.queryParams['date'] + 'T00:00:00');
      this.selectedStartDate$.next(this.route.snapshot.queryParams['date']);
    }
  }

  getStatusText = (rowData: any) => {
    const statusMap = {
      1: 'In Stock',
      3: 'Receiving',
      4: 'Returned',
      5: 'Laned',
      6: 'Picked',
      10: 'Shipped',
      20: 'Deleted'
    };
    return statusMap[rowData.statusId] || `Unknown (${rowData.statusId})`;
  }

  public async regenerateReport() {
    if (this.generating$.value) return;
    
    try {
      this.generating$.next(true);
      this.dataIsChanging = true;
      const dateStr = this.selectedStartDate.toISOString().split('T')[0];
      this.utilities.notifySuccess('Report is being generated. This can take up to one minute');
      const callable = this.fns.httpsCallable('generateStockReport');
      await callable({ dateStart: dateStr }).toPromise();
      
      // The currentReport$ will automatically update when the document changes
      this.utilities.notifySuccess('Report regenerated successfully');
    } catch (error) {
      this.utilities.notifyError('Error regenerating report');
      console.error('Error regenerating report:', error);
    } finally {
      this.generating$.next(false);
      this.dataIsChanging = false;
    }
  }
} 