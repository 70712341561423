import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report.component';
import { DxDataGridModule, DxDateBoxModule } from 'devextreme-angular';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DxDateBoxModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    ReportComponent
  ]
})
export class ReportsModule { } 