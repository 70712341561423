<div class="avatar-container" [style.transform]="'scale(' + scale + ')'">
    <div class="avatar" [style.color]="routeColor$ | async" [style.background-color]="routeColor$ | async" [innerHTML]="carrierInitial$ | async"></div>
    <div 
        class="lock-button"
        *ngIf="withLock"
        [style.color]="(hasRole$('dispatcher-advanced') | async) ? (lockColor$ | async) : '#cccccc'"
        [style.pointer-events]="(hasRole$('dispatcher-advanced') | async) ? 'auto' : 'none'"
        [style.cursor]="(hasRole$('dispatcher-advanced') | async) ? 'pointer' : 'default'"
        (click)="toggleLock(); $event.stopPropagation()">
        <app-icon [name]="lockIcon$ | async"></app-icon>
    </div>
</div>
<div class="content" [style.margin-left]="scale === 1 ? '10px' : '6px'">
    <div class="driver-name" [innerText]="driverName$ | async | titlecase"
        [ngStyle]="{'font-weight': scale === 1 ? 700 : 600, 'font-size': scale === 1 ? '13px' : '10px'}"></div>
    <div class="carrier-and-route-stat" style="display: flex; align-items: center">
        <div class="badge" *ngIf="badge"
            [style.background-color]="badge.color"
            [innerText]="badge.text"></div>
        <div class="carrier-name" [innerText]="carrierName$ | async | uppercase"></div>
    </div>
</div>