import { Injectable } from '@angular/core';
import { DataService, CollectionQuery, WithDocId, DeepPartial, BatchTransaction } from '../data.service';
import { DataPathsService } from '../data-paths.service';
import { switchMap, map, tap } from 'rxjs/operators';
import { CustomerConfirmation, JobDoc } from './job-type';
import { toPromise } from '../util';
import { FieldValue, Timestamp } from 'firebase/firestore';
import { MessageDoc } from '../messages/messages-types';
import { SlServicesService } from '../../sl-services/sl-services.service';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { DataEnvService } from '../data-env.service';

@Injectable()
export class JobDatService {
    constructor(
        private dataCtrl: DataService,
        private paths: DataPathsService,
        private services: SlServicesService,
        private dataEnv: DataEnvService
    ) { }

    public getJob$ = (id: number) => {
        return this.paths.job$(id).pipe(
            switchMap(path => this.dataCtrl.getDoc$<JobDoc>(path)),
            map(job => new Job(job, this)),
        );
    }

    public getJobs$ = ({ date, route, hub }: { date?: string; route?: string, hub?: string }) => {
        return this.paths.jobs$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<JobDoc>({
                    path,
                    queries: [
                        (route !== undefined ? ['scheduling.itinerary.route_id', '==', route] : null),
                        (date ? ['scheduling.itinerary.date', '==', date] : null),
                        (hub ? ['hub', '==', hub] : null),
                    ].filter(Boolean) as CollectionQuery
                });
            }),
            map(rts => rts.map(jb => new Job(jb, this))),
        );
    }

    public getAllJobs$ = (date: string, hub?: string) => {
        return this.getJobs$({ date, hub });
    }

    public getDefaultHub$(){
        return this.paths.base$.pipe(
            switchMap(path => {
                return this.dataCtrl.getDocNoCache$<any>(path);
            }),
            map(doc => doc.data()!.defaultHub),
        );
    }

    public getJobsByOrderId$ = (orderId: number) => {
        return this.paths.jobs$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<JobDoc>({
                    path,
                    queries: [
                        ['orderId', '==', orderId]
                    ].filter(Boolean) as CollectionQuery
                });
            }),
            map(rts => rts.map(jb => new Job(jb, this))),
        );
    }

    public async updateJob(id, data: DeepPartial<JobDoc>) {
        const path = await toPromise(this.paths.job$(id));
        const {notes, ...rest} = data;
        if (rest.address) {
            rest.address = await this.services.callService(`dispatcherGeolocalizeCustomerAddress`, rest.address );
            if(rest.address.geometry?.lat === 0 || rest.address.geometry?.lng === 0){
                throw new Error('Invalid address');
            }
        }

        this.dataCtrl.updateDoc<JobDoc>(path, rest);
        if (notes) {
            this.dataCtrl.updateDoc<JobDoc>(path, { notes: firebase.firestore.FieldValue.delete() as any });
            this.dataCtrl.updateDoc<JobDoc>(path, { notes });
        }

    }

    public async deleteJob(id, data: DeepPartial<JobDoc>) {
        const path = await toPromise(this.paths.job$(id));
        await this.dataCtrl.deleteDoc<JobDoc>(path);
    }

    private async getMaxJobId(){
        const path = await toPromise(this.paths.jobs$);
        const snapshot = await toPromise(this.dataCtrl.getCollection$<JobDoc>({
            path,
            orderBy: 'id',
            orderDirection: 'desc',
            limit: 1
        }));
        const maxId = snapshot[0]?.id || 0;
        return maxId;
    
    }

    public async getNextJobDetailId(): Promise<number>{
        const path = await toPromise(this.paths.base$);
        const snapshot = await toPromise(this.dataCtrl.getDocNoCache$<any>(path));
        const lastJobDetailId = snapshot.data()?.lastJobDetailId || 0;
        const newId = lastJobDetailId + 1;
        await this.dataCtrl.updateDoc(path, { lastJobDetailId: newId });
        return newId;
    }

    public async getNextOrderId(): Promise<number>{
        const path = await toPromise(this.paths.base$);
        const snapshot = await toPromise(this.dataCtrl.getDocNoCache$<any>(path));
        const lastOrderId = snapshot.data()?.lastOrderId || 0;
        const newId = lastOrderId + 1;
        await this.dataCtrl.updateDoc(path, { lastOrderId: newId });
        return newId;
    }

    public async insertJob(data: DeepPartial<JobDoc>) {
        const newJobId = await this.getMaxJobId() + 1;
        const defaultHub = await toPromise(this.getDefaultHub$());
        const selectedHub = await toPromise(this.dataEnv.selectedHub$);
        data.id = newJobId;
        const hub = selectedHub || defaultHub; 
        if(hub){
            data.hub = hub;
        }
        await this.updateJob(newJobId, data);
        return newJobId;
    }

    public async updateJobs(updates: { id; data: DeepPartial<JobDoc>; }[]) {
        const jobsPath = await toPromise(this.paths.jobs$);
        const changes: BatchTransaction<JobDoc>[] = updates
            .map(({ id, data }) => ({
                path: `${jobsPath}/${id}`,
                data,
            }));
        return this.dataCtrl.batchUpdateDocuments<JobDoc>(changes);
    }

    public async updateItemStatus(jobId: number, jobDetailId: number, status: string) {
        return await this.services.callService(`dispatcherSetItemStatus`, { jobId, jobDetailId, status });
    }

    public async getSkus(name: string): Promise<Observable<{name: string, description: string}[]>>{
        const path = await toPromise(this.paths.itemMasterList$);
        const result = this.dataCtrl.getCollection({path, queries: [['barcode', '>=', name], ['barcode', '<=', name + '\uf8ff']], limit: 10 });
        return result.pipe(
            map(res => {   
                return res.docs.map(d => { 
                return {name: d.data().barcode as string, description: d.data().description as string}})
            })
        );
    }
}

export class Job {
    constructor(
        private jobDoc: WithDocId<JobDoc>,
        private jobData: JobDatService
    ) { }

    public routeId = this.jobDoc.scheduling.itinerary.route_id;
    public id = this.jobDoc.id;
    public companyId = this.jobDoc.companyId;
    public keyPONum = this.jobDoc.keyPONum;
    public customer = this.jobDoc.customer;
    public items = this.jobDoc.items || [];
    public scheduling = this.jobDoc.scheduling;
    public status = this.jobDoc.status;
    public statusTime = this.jobDoc.statusTime;
    public address = this.jobDoc.address;
    public notes = this.jobDoc.notes;
    public erp_sync_status = this.jobDoc.erp_sync_status;
    public erp_sync_error = this.jobDoc.erp_sync_error;
    public erp_sync_attempts = this.jobDoc.erp_sync_attempts;
    public get notesArr() {
        return Object.values(this.jobDoc.notes || {});
    }
    public serviceTime = this.jobDoc.service_time;
    public _jobDoc = this.jobDoc;
    public stopNumber = this._jobDoc.scheduling.itinerary.stop_number;
    public arrivalEta = (this._jobDoc.scheduling.itinerary.arrival_eta || { toDate: () => new Date() }).toDate();
    public arrivalTimeWindowStart = this._jobDoc.scheduling.itinerary.arrivalTimeWindowStart?.toDate();
    public arrivalTimeWindowEnd = this._jobDoc.scheduling.itinerary.arrivalTimeWindowEnd?.toDate();
    public distanceFromPrev = this._jobDoc.scheduling.itinerary.distance_from_previous || 0;
    public durationFromPrev = this._jobDoc.scheduling.itinerary.duration_from_previous_traffic || 0;
    public durationFromPrevTraffic = this._jobDoc.scheduling.itinerary.duration_from_previous_traffic || 0;
    public messages? = this._jobDoc.messages;
    public photos? = this._jobDoc.photos;
    public orderId = this._jobDoc.orderId;
    public salesrep? = this._jobDoc.salesrep;
    public customerPOnum? = this._jobDoc.customerPOnum;
    public coordinated? = this._jobDoc.coordinated;
    public sequence? = this._jobDoc.sequence;
    public unresolvedMessages = this._jobDoc.unresolvedMessages;
    public startBefore = this._jobDoc.startBefore;
    public startAfter = this._jobDoc.startAfter;
    public thirdMan = this._jobDoc.thirdMan;
    public vip = this._jobDoc.vip;
    public revenue = this._jobDoc.revenue || 0;
    public orderJobMessages: {id: number, messages: MessageDoc[]} | null = null;
    public routeName?: string;
    public actualArrivalTime? = this._jobDoc.actualArrivalTime?.toDate();
    public actualEndTime? = this._jobDoc.statusId === 1 || this._jobDoc.statusId === 2 ? this._jobDoc.statusTime?.toDate() : undefined;
    public openingTime? = this._jobDoc.opening_time;
    public closingTime? = this._jobDoc.closing_time;

    public async updateServiceTime(time: number) {
        return this.jobData.updateJob(this.id, { service_time: time });
    }

    public async updateConfirmationStatus(customerConfirmation: CustomerConfirmation) {
        return this.jobData.updateJob(this.id, { scheduling: { customerConfirmation: customerConfirmation }});
    }

    public async updateDeliveryStatus(status: 'completed' | 'not_home' | '') {
        let statusId = 0;
        switch (status) {
            case 'completed':
                statusId = 1;     
                break;
            case 'not_home':
                statusId = 2;
                break;
        }
        return this.jobData.updateJob(this.id, { statusId });
    }

    public async updateItemStatus(jobDetailId: number, status: string) {
        return this.jobData.updateItemStatus(this.id, jobDetailId, status);
    }

    public updateCustomerPhone(phone: any) {
        this.jobData.updateJob(this.id, { customer: { phone: phone } });
    }

    public updateStopNumber(num: number) {
        this.jobData.updateJob(this.id, { scheduling: { itinerary: { stop_number: num } } });
    }

    public updateTimeWindow(arrivalTimeWindowStart: Date, arrivalTimeWindowEnd: Date) {
        this.jobData.updateJob(this.id, { scheduling: { itinerary: { arrivalTimeWindowUpdatedManually: true, arrivalTimeWindowStart: Timestamp.fromDate(arrivalTimeWindowStart), arrivalTimeWindowEnd: Timestamp.fromDate(arrivalTimeWindowEnd)} } });
    }

    public async update(job: Job) {
        await this.jobData.updateJob(this.id, job);
    }

    public async delete(job: Job) {
        await this.jobData.deleteJob(this.id, job);
    }

    public get isAssignedToRoute() {
        return !!this.routeId;
    }

    public get numberOfPhysicalItems(){
        return this.items.reduce((total, cIt) => { 
            const serviceMustBeCounted = ['DELIVERY','PICKUP'].includes(cIt.sku);
            const hasAnItem = cIt.subItems?.length===1 && cIt.subItems[0].type==='I';
            const quantity = hasAnItem && Number.isInteger(cIt.subItems[0].quantity) ? cIt.subItems[0].quantity : 1;
            const jobTotal = serviceMustBeCounted && hasAnItem ? quantity : 0;
            return total + jobTotal;
        }, 0);
    }

    public getAlerts(){
        const alerts = {
            missingStock: false,
            missingConfirmation: false,
            poorAddress: false,
            etaOutOfTimeWindow: false,
            alertsText: ''
        };
        let alertsText = '';

        if(this.items.some(i=>i.subItems?.some(si=>si.stockStatus==='NOT_ENOUGH_STOCK' || si.stockStatus === 'NO_STOCK'))){
            alertsText = `Missing stock\r\n`;
            alerts.missingStock = true;
        }
        if(this.address?.geometry?.score && this.address?.geometry?.score < 0.8){
            alertsText = `${alertsText}Poor address geolocalization score\r\n`;
            alerts.poorAddress = true;
        }
        if (this.arrivalEta && this.arrivalTimeWindowStart && this.arrivalTimeWindowEnd) {
            if (this.arrivalEta < this.arrivalTimeWindowStart || 
                this.arrivalEta > this.arrivalTimeWindowEnd) {
                alertsText = `${alertsText}ETA out of time window\r\n`;
                alerts.etaOutOfTimeWindow = true;
            }
        }

        // Check for missing confirmation when ETA is less than 4 hours away
        const finishStatuses = ['completed', 'delivered', 'invoiced', 'not_home', 'partial'];
        const noConfirmationStatuses = ['sent', 'failed_delivery', 'no_response'];
        const notFinished = finishStatuses.indexOf(this.status) < 0;
        const noConfirmation = noConfirmationStatuses.indexOf(this.scheduling.customerConfirmation) >= 0;
        if (notFinished && noConfirmation && this.arrivalEta) {
            
            const fourHoursFromNow = new Date();
            fourHoursFromNow.setHours(fourHoursFromNow.getHours() + 4);
            const now = new Date();
            if (this.arrivalEta > now && this.arrivalEta <= fourHoursFromNow) {
                alertsText = `${alertsText}Missing customer confirmation (eta in less than 4 hours from now)\r\n`;
                alerts.missingConfirmation = true;
            }
        }

        alerts.alertsText = alertsText;
        return alerts;
    }

}
