import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Observable, ReplaySubject, fromEvent, merge, firstValueFrom, interval, lastValueFrom, async } from 'rxjs';
import { JobDocOld, RouteStop } from 'src/typings/job';
import { switchMap, map, shareReplay, startWith, tap, filter, mapTo, distinctUntilChanged, take } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { intersectionWith, merge as _merge } from 'lodash';
import { Job, JobDatService } from '../shared/sl-data/job';
import { RouteDataService, Route } from '../shared/sl-data/route';
import { GeoLocation, StopList } from '../shared/sl-data/location-types';
import { CarrierData } from 'src/app/shared/sl-data/carrier';
import { RouteZone, RouteZoneDoc } from '../shared/sl-data/route/route-type';
import { DataEnvService } from '../shared/sl-data/data-env.service';
import { UserPreferences } from '../shared/sl-components/user-preferences/userPreferences.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root'
})
export class PlanningState {
    private _selectedDate$ = new BehaviorSubject(null);
    private _activeStop$ = new BehaviorSubject<Job>(null);
    private _hoveringStop$ = new BehaviorSubject<Job>(null);
    private _inDraggingState$ = new BehaviorSubject(null);
    private _selectedMapStop$ = new BehaviorSubject<RouteStop>(null);
    private _visibleStopLists$ = new BehaviorSubject<StopList[]>([]);
    public _selectedStopList$ = new ReplaySubject<StopList>();
    public _multiSelectedStops$ = new BehaviorSubject<Job[]>([]);
    public timeZone = 'America/New_York';
    public showingCurrentRoutes$ = new BehaviorSubject<Route[]>([]);
    public defaultSelectedDate = this.date.dateToISO(new Date());
    private today = this.date.dateToISO(this.date.now());
    
    public userPreferences$ = new BehaviorSubject<UserPreferences>({
        mapStraightLines: true,
        mapType: 'street'
    });
    
    constructor(
        private routeData: RouteDataService,
        private jobData: JobDatService,
        private date: DateUtilsService,
        private carrierData: CarrierData,
        private dataEnv: DataEnvService,
        private fns: AngularFireFunctions,
    ) {
        this.date.setDefaultTimezon(this.timeZone);
        this.setSelectedDate(this.defaultSelectedDate);
        
        interval(10000).subscribe(
            () => {
                const todayRecalculated = this.date.dateToISO(this.date.now());
                if(this.today !== todayRecalculated){
                    window.location.reload();
                }
            }
        );

        // listen to escape key press
        fromEvent<KeyboardEvent>(document, 'keydown')
            .pipe(filter(ev => ev.keyCode === 27))
            .subscribe(() => this.clearMultiSelectionStops());

        // listen to ctrl+a key press
        this.selectedStopList$.pipe(
            switchMap(selected => {
                return selected
                    ? fromEvent<KeyboardEvent>(document, 'keydown')
                        .pipe(
                            filter(ev => ev.keyCode === 65 && ev.ctrlKey),
                            mapTo(selected),
                        )
                    : of();
            })
        ).subscribe((selected: StopList) =>
            selected.stops
                .filter(stop => stop.type === 'stop')
                .forEach(stp => this.addMultiSelectionStops(stp))
        );

        this.loadUserPreferences();
    }

    public featureFlags$ = this.dataEnv.featureFlags$;
    public carriers$ = this.carrierData.carriers$;

    public routes$ = combineLatest([this.selectedDate$, this.dataEnv.selectedHub$])
    .pipe(
        switchMap(([selectedDate, selectedHub])=>{
            return this.routeData.getRoutes$(selectedDate, this.currentDateStops$, selectedHub);
        }),
        shareReplay()
    );

    public routeZones$: Observable<RouteZone[]> = this.routeData.getRouteZones$();
    
    public _currentDateStops$ = combineLatest([this.selectedDate$, this.dataEnv.selectedHub$])
    .pipe(
        switchMap(([selectedDate, selectedHub])=>{
            return this.jobData.getAllJobs$(selectedDate, selectedHub)
        }),
        shareReplay()
    );

    public currentDateStops$: Observable<Job[]> = combineLatest([this._currentDateStops$, this.routes$, this.featureFlags$]).pipe(
        map(([currentDateStops, routes, featureFlags])=>{
            return currentDateStops.map(s => { 
                const route = s.routeId ? routes.find(r=>r.id===s.routeId) : null;
                if(route){
                    if((featureFlags as any).virtualDrivers){
                        s.routeName = `${route.lastVirtualCarrierName || 'No route'}: ${(!route.virtualCarrier) && route.carrierName ? route.carrierName : 'No Carrier Assigned'}`;
                    }
                    else{
                        s.routeName = route.carrierName;
                    }
                }
                return s;
            });
        })
    );

    public unAssignedStops$ = this.currentDateStops$
        .pipe(
            map(jobs => jobs.filter(j=>!j.routeId)),
            shareReplay()
        );

    public assignedStops$ = this.currentDateStops$
    .pipe(
        map(jobs => jobs.filter(j=>j.routeId)),
        shareReplay()
    );

    public routeStopLists$ = combineLatest([this.routes$, this.currentDateStops$]).pipe(
        map(([routes, stops])=>{
            return routes.map(r=>{
                const routeJobs = stops.filter(j=>j.routeId===r.id).sort((x, y) => x.stopNumber - y.stopNumber);
                return {
                        stops: [
                            ...routeJobs.map(stp => Object.assign(stp, { type: 'stop' })),
                            { address: r.startPoint, type: 'start' },
                            { address: r.endPoint, type: 'end' },
                        ],
                        type: 'route',
                        route: r,
                        color: r.color,
                    } as StopList
            });
        }),
        startWith([])
    )

    public assignedStopList$ = this.assignedStops$.pipe(
        map(stops => ({
            type: 'assigned',
            stops: stops.map(stp => _merge(stp, { type: 'stop' })),
            color: '#3ca337'
        }) as StopList)
    );

    public unAssignedStopList$ = this.unAssignedStops$.pipe(
        map(stops => ({
            type: 'unassigned',
            stops: stops.map(stp => _merge(stp, { type: 'stop' })),
            color: '#ff1500'
        }) as StopList)
    );

    public allStopList$ = combineLatest(
            this.assignedStops$,
            this.unAssignedStops$
        ).pipe(
            map(([assigned, unassigned]) => { 
                this.setOrderMessagesJobs([...assigned, ...unassigned]);
                return ({
                    type: 'all',
                    stops: [...assigned, ...unassigned].map(stp => _merge(stp, { type: 'stop' })),
                    color: '#ff1500'
                }) as StopList
            })
        );

    private setOrderMessagesJobs = (jobs: Job[]): Job[] => {
        const coordinatedJobs = jobs.filter(j=>j.coordinated);
        coordinatedJobs.forEach(j=>j.orderJobMessages = null);
        coordinatedJobs.forEach(j=>{
            if(!j.orderJobMessages){
                const jobsInOrder = coordinatedJobs.filter(cj => cj.orderId === j.orderId);
                const minJobInOrder = coordinatedJobs.find(cj => cj.id === Math.min(...jobsInOrder.map(j=>j.id)));
                jobsInOrder.forEach(jio => {
                    jio.orderJobMessages = {id: minJobInOrder.id, messages: minJobInOrder.messages || []};
                });
            }
        });
        return jobs;
    }

    public pendingMessagesStopList$ = combineLatest(
            this.assignedStops$,
            this.unAssignedStops$
        ).pipe(
            map(([assigned, unassigned]) => (
                {
                    type: 'pendingMessages',
                    stops: [...assigned, ...unassigned].filter(s=>s.unresolvedMessages).map(stp => _merge(stp, { type: 'stop' })),
                    color: '#ff1500'
                }
            ) as StopList)
        );

    public stopLists$: Observable<StopList[]> = combineLatest(
        combineLatest(
            this.routeStopLists$,
            this.assignedStopList$,
            this.unAssignedStopList$,
            this.allStopList$,
            this.carriers$,
            this.pendingMessagesStopList$,
        ).pipe(
            map(([routes, assigned, unassigned, all, carriers, pendingMessagesStopList]) => 
            {
                all.routes = routes.map(sl=>{
                    const route = sl.route;
                    const color = carriers.find(c=>c.id===route.carrierId)?.color;
                    route.truckColor = color;
                    return route;
                });
                assigned.routes = routes.map(sl=>sl.route);
                unassigned.routes = [];
                const assignedRoutes = routes.filter(stoplist => stoplist.route && !carriers.find(c=>c.id===stoplist.route.carrierId)?.virtual);
                const unassignedRoutes = routes.filter(stoplist => stoplist.route && carriers.find(c=>c.id===stoplist.route.carrierId)?.virtual === true);
                const assignedRoutesStopList = ({
                    type: 'assignedRoutes',
                    stops: [...assignedRoutes.map(r=>r.stops.filter(s=>s.type==='stop').map(stp => _merge(stp, { type: 'stop' })))].flat(),
                    color: '#ff1500',
                    routes: assignedRoutes.map(sl=>sl.route)
                } ) as StopList;
                const unassignedRoutesStopList = ({
                    type: 'unassignedRoutes',
                    stops: [...unassignedRoutes.map(r=>r.stops.filter(s=>s.type==='stop').map(stp => _merge(stp, { type: 'stop' })))].flat(),
                    color: '#ff1500',
                    routes: unassignedRoutes.map(sl=>sl.route)
                }) as StopList;
                const allRoutedStopList = ({
                    type: 'allRoutedStops',
                    stops: [...routes.map(r=>r.stops.filter(s=>s.type==='stop').map(stp => _merge(stp, { type: 'stop' })))].flat(),
                    color: '#ff1500',
                    routes: routes.map(sl=>sl.route)
                }) as StopList;

                return ([...routes, all, assigned, unassigned, allRoutedStopList, assignedRoutesStopList, unassignedRoutesStopList, pendingMessagesStopList]) as StopList[];
            })
        ),
        this._selectedStopList$,
        this._visibleStopLists$,
    ).pipe(
        map(([lists, selected, visible]) => {
            return lists.map(list => ({
            ...list,
            selected: this.sameStopLists(selected, list),
            visible: visible.some(vList => this.sameStopLists(vList, list)),
        }))}),
        startWith([]),
        shareReplay()
    );

    public addStopListVisibility(list: StopList) {
        this._visibleStopLists$.next([...this._visibleStopLists$.value, list]); 
    }

    public removeStopListVisibility(stopList: StopList) {
        this._visibleStopLists$.next(
            this._visibleStopLists$.value.filter(list => !this.sameStopLists(stopList, list)));
    }

    public isStopListVisible(list: StopList){
        return this._visibleStopLists$.value.find(currentList => this.sameStopLists(list, currentList));
    }


    public removeAllVisibleStopLists() {
        this._visibleStopLists$.next([]);
    }

    public visibleStopLists$ = combineLatest(
        this.stopLists$,
        this._visibleStopLists$
    ).pipe(
        map(([lists, visible]) => {
            return intersectionWith(lists, visible, this.sameStopLists);
        })
    );

    public timelineFullview$ = new BehaviorSubject(true);

    public setSelectedStopList(stopList: StopList) {
        this._selectedStopList$.next(stopList);
        this.clearActiveStop();
        this.clearMultiSelectionStops();
    }

    public resetSelectedStopList() {
        this._selectedStopList$.next(null);
        this.clearActiveStop();
        this.clearMultiSelectionStops();
    }

    public selectedStopList$ = combineLatest(
        this.stopLists$,
        this._selectedStopList$
    ).pipe(
        map(([lists, selected]) => {
            return lists.find(list => this.sameStopLists(list, selected));
        })
    );

    public addMultiSelectionStops(stop: Job) {
        if (!this.isMultiSelected(stop)) {
            this._multiSelectedStops$.next([...this._multiSelectedStops$.value, stop]);
        }
    }

    public removeStopFromMultiSelect(stop: Job) {
        this._multiSelectedStops$.next(
            this._multiSelectedStops$.value.filter(({ id }) => id !== stop.id));
    }

    public clearMultiSelectionStops() {
        this._multiSelectedStops$.next([]);
    }

    public multiSelectionStops$ = this._multiSelectedStops$;

    public isMultiSelected(stop: Job) {
        return !!this._multiSelectedStops$.value
            .find(({ id }) => id === stop.id);
    }

    public currentRouteForCarriers$ = this.showingCurrentRoutes$.pipe(
            filter(currentRoutes => currentRoutes?.length>0 ),
            switchMap(currentRoutes => combineLatest(currentRoutes.map(cr => this.routeData.getCurrentRouteForCarrier$(cr)))),
            shareReplay()
        );

    public currentRouteList$ = combineLatest(this.currentRouteForCarriers$, this.showingCurrentRoutes$, this.visibleStopLists$).pipe(
        map(([currentRouteForCarriers, showingCurrentRoutes, visibleStopLists]) => {
            const routePositions = currentRouteForCarriers
                .filter(cr=>visibleStopLists.some(vsl=>vsl?.route?.id === cr.routeId))
                .filter(cr=>showingCurrentRoutes.some(srl=>srl.id === cr.routeId))
                .map(cr => { 
                    return {positions: cr.positions, color: cr.color || '#aaaaaa'}}).flat();
            const routeList = routePositions.map(routePosition => {return {color: routePosition.color, positions: routePosition.positions.map(position => ({ dateTime: new Date(position.split('|')[2]), lng: Number.parseFloat(position.split('|')[0]), lat: Number.parseFloat(position.split('|')[1]) }))}});
            return routeList;
        }),
        distinctUntilChanged((prev, curr) => {
            return JSON.stringify(prev) === JSON.stringify(curr);
        })
    );

    public async showingCurrentRoute(route: Route, action: 'add' | 'remove') {
        const currentRoutes = this.showingCurrentRoutes$.value;
        if(action === 'add'){
            if(!currentRoutes.some(r=>r.id === route.id)){
                currentRoutes.push(route);
                this.showingCurrentRoutes$.next(currentRoutes);
            }
        }
        if(action === 'remove'){
            this.showingCurrentRoutes$.next(currentRoutes.filter(r=>r.id !== route.id));
        }
    }

    public get selectedDate$() {
        return this._selectedDate$.asObservable();
    }

    public setSelectedDate(IsoDate: string) {
        this.setSelectedStopList(null);
        this.removeAllVisibleStopLists();
        return this._selectedDate$.next(IsoDate);
    }

    public setSelectedDateToDefault() {
        return this.setSelectedDate(this.defaultSelectedDate);
    }

    public activeStop$ = combineLatest(
        this.currentDateStops$,
        this._activeStop$
    ).pipe(
        map(([currentDateStops, activeStop]) => {
            if(!activeStop){
                return null;
            }
            const job = currentDateStops?.find(jb => jb.id ===activeStop.id);
            if(job){
                return job;
            }
            
        })
    );

    public setActiveStop(stop: Job) {
        return this._activeStop$.next(stop);
    }

    public clearActiveStop() {
        return this._activeStop$.next(null);
    }

    public get hoveringStop$() {
        return this._hoveringStop$.asObservable();
    }

    public setHoveringStop(stop: Job) {
        return this._hoveringStop$.next(stop);
    }

    public getHoveringStop() {
        return this._hoveringStop$.value;
    }

    public clearHoveringStop() {
        if(this._hoveringStop$.value){
            return this._hoveringStop$.next(null);
        }
    }

    private currentRouteJobDropPosition: number = null;
    public getCurrentRouteJobDropPosition(){
        return this.currentRouteJobDropPosition;
    }
    public setCurrentRouteJobDropPosition(position: number){
        this.currentRouteJobDropPosition = position;
    }
    public clearCurrentRouteJobDropPosition(){
        this.currentRouteJobDropPosition = null;
    }

    public get inDraggingState$() {
        return this._inDraggingState$;
    }

    public get selectedMapStop$() {
        return this._selectedMapStop$.asObservable();
    }

    public get inDraggingState() {
        return this._inDraggingState$.value;
    }


    public setInDraggingState(dragging: boolean) {
        this._inDraggingState$.next(dragging);
    }

    public setSelectedMapStop(stop: JobDocOld) {
        this._selectedMapStop$.next(stop);
    }

    public inMultiSelectMode$ = combineLatest(
        merge(
            fromEvent<KeyboardEvent>(document, 'keydown')
                .pipe(filter(ev => ev.keyCode === 17), mapTo(true)),
            fromEvent<KeyboardEvent>(document, 'keyup')
                .pipe(filter(ev => ev.keyCode === 17), mapTo(false)),
        ),
        this.selectedStopList$,
    ).pipe(
        map(([ctrlPressed, listSelected]) => ctrlPressed && listSelected && !listSelected.route?.locked),
        startWith(false),
        shareReplay(1),
        distinctUntilChanged()
    );

    private sameStopLists = (x: StopList, y: StopList) => {
        if (!(x && y)) {
            return false;
        }
        if (x.type !== y.type) {
            return false;
        }
        if (x.route?.id !== y.route?.id) {
            return false;
        }
        if (x.itineraryName !== y.itineraryName) {
            return false;
        }
        return true;
    }

    private async loadUserPreferences() {
        try {
            const callable = this.fns.httpsCallable('getPreferences');
            const preferences = await callable({}).toPromise();
            this.userPreferences$.next(preferences);
        } catch (error) {
            console.error('Error loading user preferences:', error);
        }
    }
}
