import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from 'src/app/core/auth.service';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class DataEnvService {
    public comId$ = new ReplaySubject(1);
    public featureFlags$ = new ReplaySubject(1);
    public hubs$ = new ReplaySubject<string[]>(1);
    public userRoles$ = new ReplaySubject<string[]>(1);
    public selectedHub$ = new ReplaySubject<string | null>(1);

    constructor(    
        private fns: AngularFireFunctions,
        private auth: AuthService,
        private router: Router,
    ) {
        this.userRoles$.next([]);
    }

    public setComId(comId: string) {
        this.comId$.next(comId);
    }

    public hasRole$(role:string): Observable<boolean> {
        return this.userRoles$.pipe(
            map((userRoles: string[] = []) => {
                if (!userRoles || userRoles.length === 0) {
                    return true; //to be retrocompatible. Users with no roles are admin
                }
                return userRoles.indexOf(role) >= 0;
            })
        );
    } 
        
    public async setComFromCurrentUser(){
        const callable = this.fns.httpsCallable(`dispatcherGetCurrentEnvironmentData`);
        
        try{
            await callable({}).forEach(response => {
                const environmentPath = response?.environment?.environmentPath;
                if(!environmentPath){
                    console.log("^******** User is not authenticated ***************");
                    this.auth.signOut();
                    this.router.navigate(['/login']);
        
                }
                else
                {
                    this.comId$.next(environmentPath);
                    this.featureFlags$.next(response?.featureFlags);
                    const hubs = response?.environment?.hubs;
                    this.hubs$.next(hubs);
                    if(hubs && hubs.length>0){
                        this.selectedHub$.next(hubs[0]);
                    }
                    else{
                        this.selectedHub$.next(null);
                    }
                    this.userRoles$.next(response?.environment?.roles);
                }
              });
        }
        catch(err){
            console.log("^******** ERROR getting user ***************");
            this.auth.signOut();
            this.router.navigate(['/login']);
        }
    }
}
