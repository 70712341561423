import { Component, OnInit, ChangeDetectionStrategy, ViewChild, NgZone, ElementRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { CollectionQuery, DataService, DeepPartial } from '../shared/sl-data/data.service';
import { DataPathsService } from '../shared/sl-data/data-paths.service';
import { switchMap, map, filter } from 'rxjs/operators';
import { RouteDoc } from '../shared/sl-data/route/route-type';
import { Route } from '../shared/sl-data/route';
import { waitForAsync } from '@angular/core/testing';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { toPromise } from '../services/utils';
import { MatSort, MatSortable } from '@angular/material/sort';
import { CarrierData } from '../shared/sl-data/carrier';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import * as moment from 'moment';
import { DataEnvService } from '../shared/sl-data/data-env.service';
import { firstValueFrom } from 'rxjs';

interface CarrierDefaults {
  startTime: string;
  endTime: string;
  startLocation?: {
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    postalCode: string;
  };
  endLocation?: {
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    postalCode: string;
  };
}

interface CarrierItem {
  docId: string;
  name: string;
  defaults: CarrierDefaults;
  virtual?: boolean;
  email?: string;
  color?: string;
  number_of_staff?: number;
  max_weight?: number;
  cubic_feet?: number;
  priority?: number;
  installed_on_truck?: boolean;
  userId?: string;
  updated_at?: Date;
  created_at?: Date;
  routingUpdated_at?: Date;
}

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CarriersComponent implements OnInit {
  displayedColumns: string[] = [
    'color',
    'name',
    'startTime',
    'endTime',
    'number_of_staff',
    'installed_on_truck',
    'virtual',
    'max_weight',
    'cubic_feet',
    'priority',
    'userId',
    'active'];

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  expandedElement: any;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<CarrierItem> = new MatTableDataSource([]);
  carriersData$ = new BehaviorSubject([]);
  columnSetupReady$ = new BehaviorSubject(false);
  loading: boolean;
  // @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  constructor(private dataCtrl: DataService, private paths: DataPathsService, public ngZone: NgZone, public carriers: CarrierData, public dialog: MatDialog, public dataEnv: DataEnvService) { }

  public featureFlags$ = this.dataEnv.featureFlags$;

  async ngOnInit() {
    const fflags: any = await firstValueFrom(this.featureFlags$);
    this.displayedColumns = this.displayedColumns.filter(col => col!=='virtual' || fflags?.virtualCarrier);
    this.columnSetupReady$.next(true);
    
    // Configure sorting
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'startTime': return item.defaults?.startTime || '';
        case 'endTime': return item.defaults?.endTime || '';
        case 'name': return item.name || '';
        case 'number_of_staff': return item.number_of_staff || 0;
        case 'installed_on_truck': return item.installed_on_truck || false;
        case 'virtual': return item.virtual || false;
        case 'max_weight': return item.max_weight || 0;
        case 'cubic_feet': return item.cubic_feet || 0;
        case 'priority': return item.priority || 0;
        default: return item[property];
      }
    };

    const path = await toPromise(this.paths.carriers$);
    const selectedHub = await toPromise(this.dataEnv.selectedHub$);
    
    this.loading = false;
    
    this.dataCtrl.getCollection$({ 
      path: path, 
      queries: [selectedHub ? ['hub', '==', selectedHub] : null].filter(Boolean) as CollectionQuery }
    ).subscribe((res) => {
      res.forEach((d, index) => {
        res[index]['defaults']['startTime'] = moment().format('YYYY-MM-DD') + ' ' + res[index]['defaults']['startTime'];
        res[index]['defaults']['endTime'] = moment().format('YYYY-MM-DD') + ' ' + res[index]['defaults']['endTime'];
        if (!res[index]['virtual']){
          if(fflags?.virtualCarrier){
            res[index]['virtual'] = false;
          }
        }
        if (!res[index]['email']){
          res[index]['email'] = "";
        }
        if (!res[index]['color']){
          res[index]['color'] = "#dedede";
        }
      });

      this.carriersData$.next(res);
    });

    this.carriersData$.subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  edit(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.data = element;
    const dialogRef = this.dialog.open(EditModalComponent, dialogConfig);
  }

  async clone(element) {
    const path = await toPromise(this.paths.carriers$);
    const clonedElement = JSON.parse(JSON.stringify(element));
    delete clonedElement.docId;
    delete clonedElement.created_at;
    delete clonedElement.routingUpdated_at;
    delete clonedElement.updated_at;
    delete clonedElement.userId;
    clonedElement.defaults.startTime = clonedElement.defaults.startTime ? new Date(clonedElement.defaults.startTime).toLocaleTimeString('en-US', { hour12: false }) : '';
    clonedElement.defaults.endTime = clonedElement.defaults.endTime ? new Date(clonedElement.defaults.endTime).toLocaleTimeString('en-US', { hour12: false }) : '';
    console.log("element to clone:", clonedElement);

    this.dataCtrl.addDoc(path, clonedElement).then((res) => {
      console.log("Created:", res);
    });

  }

  async delete(element) {
    const path = await toPromise(this.paths.carriers$);
    
    this.dataCtrl.deleteDoc(`${path}/${element.docId}`).then((res) => {
      console.log("Deleted:", res);
    });

  }
}
